import React from 'react';
import { projectName, apiURL, decimals, denomination } from '../data/API';
import { connect } from 'react-redux';
import Axios from 'axios';
import ReactTooltip from "react-tooltip";

class Bridge extends React.Component {
  state = {
    status: "",
    pageIn: 0,
    pageOut: 0,
  }
  componentDidMount(){
    this.getStatus();
    this.getTxsIn(0);
    this.getTxsOut(0);
    this.getTokens();
    setInterval(() => {
      if(this.state.codeIn){
        this.setState({
          codeIn: "",
        })
        this.getTxsIn(this.state.pageIn);
      }
      if(this.state.codeOut){
        this.setState({
          codeOut: "",
        })
        this.getTxsOut(this.state.pageOut);
      }
      // if(this.state.code){
      //   this.setState({
      //     code: "",
      //   })
      //   this.getStatus();
      // }
      if(this.state.codeTokens){
        this.setState({
          codeTokens: "",
        })
        this.getTokens();
      }
    }, 10000);
    document.title = `Gravity Bridge | ${projectName} Scan`;
  }
  
  getStatus = () => {
    Axios.get(`${apiURL}/gravity/status`)
    .then(response => {
      this.setState({
        status: response.data,
        code: 200
      })
    })
    .catch(err => {
      this.setState({
        code: err.status
      })
      console.error("Failed to fetch gravity status.");
    })
  }
  getTokens = async() => {
    const getTokens = await Axios.get(`${apiURL}/gravity/tokens`)
    this.setState({
      tokens: getTokens.data.data,
      codeTokens: 200
    })
  }
  getTxsIn = async(page) => {
    const getIn = await Axios.get(`${apiURL}/gravity/in?page=${page}`)
    this.setState({
      txsIn: getIn.data.data,
      codeIn: 200
    })
  }
  getTxsOut = async(page) => {
    const getOut = await Axios.get(`${apiURL}/gravity/out?page=${page}`)
    this.setState({
      txsOut: getOut.data.data,
      codeOut: 200
    })
  }
  nextIn = () => {
    console.log("NEXT")
    this.setState({
      pageIn: this.state.pageIn + 1
    })
    this.getTxsIn(this.state.pageIn+1)
  }
  prevIn = () => {
    this.setState({
      pageIn: this.state.pageIn - 1
    })
    this.getTxsIn(this.state.pageIn-1)
  }
  nextOut = () => {
    console.log("NEXT")
    this.setState({
      pageOut: this.state.pageOut + 1
    })
    this.getTxsOut(this.state.pageOut+1)
  }
  prevOut = () => {
    this.setState({
      pageOut: this.state.pageOut - 1
    })
    this.getTxsOut(this.state.pageOut-1)
  }
  renderIn = () => {
    if(this.state.txsIn.length > 0){
      const dateNow = new Date();
      return(
        this.state.txsIn.map(tx => {
          var txDate = parseInt((dateNow-tx.txTime)/1000);
          if(txDate > 3600){
            txDate = parseInt(txDate/3600)+"h";
          }else if(txDate > 60){
            txDate = parseInt(txDate/60)+"m";
          }else{
            txDate = txDate+"s"
          }
          var txAmount = 0;
          var txDenom = "UMEE";
          var sender;
          var receiver;
          tx.txMessage.forEach(messages => {
            if(messages.type === "deposit claim"){
              messages.message.forEach(amount => {
                if(amount.key === "amount"){
                  txAmount += parseInt(amount.value)
                }
                if(amount.key === "token_contract"){
                  this.state.tokens.forEach(denom => {
                    if(denom.contract === amount.value){
                      txDenom = denom.denom
                      if(txDenom.length > 5 && txDenom.includes("base")){
                        txDenom = txDenom.split("base")[1].toUpperCase();
                      }else if(txDenom[0] === "u" || txDenom[0] === "a" || txDenom[0] === "t"){
                        txDenom = txDenom.substring(1);
                      }
                    }
                  })
                }
                if(amount.key === "ethereum_sender"){
                  sender = amount.value;
                }
                if(amount.key === "cosmos_receiver"){
                  receiver = amount.value;
                }
              })
            }
          })
          return(
            <a href={`/tx/${tx.txHash}`}>
              <div>
                <div className="row">
                  <div className="col-md-3 col-8">
                    {
                      tx.status === true ?
                        <p className="text-hash capitalize-text"><span className="badge bg-success rounded-pill">{tx.txMessage[0].type}</span></p>
                      :
                      <p className="text-hash capitalize-text"><span className="badge bg-danger rounded-pill">{tx.txMessage[0].type}</span></p>
                    }
                  </div>
                  <div className="col-md-3 col-4">
                    <p><span className="badge bg-secondary"><span className="stopwatch">{txDate}</span><u style={{"display": "none"}} className="timestampSaver">{parseInt(tx.txTime)}</u> ago</span></p>
                  </div>
                  <div className="col-md-3 col-6">
                    <p className="normal-text"><b>{(parseInt(txAmount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} </b><span className="badge bg-primary">{txDenom.toUpperCase()}</span>
                    </p>
                  </div>
                  <div className="col-md-3 col-6">
                    {
                      Array.isArray(tx.txFee) ?
                        <p className="normal-text">Fee <b>{(parseInt(tx.txFee[0].amount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})}</b></p>
                      : <p className="normal-text">Fee <b>{(parseInt(tx.txFee)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})}</b></p>
                    }
                  </div>
                  <div className="col-6">
                    <p className="text-hash content-table-title"><i className="bi bi-arrow-90deg-up text-danger"></i> {sender}</p>
                  </div>
                  <div className="col-6">
                    <p className="text-hash content-table-title"><i className="bi bi-arrow-return-right text-primary"></i> {receiver}</p>
                  </div>
                  <div className="col-12">
                    <p className="text-hash content-table-title">{tx.txHash}</p>
                  </div>
                </div>
              </div>
              <hr className="no-margin-hr" />
            </a>
          )
        })
      )
    }
  }
  renderOut = () => {
    if(this.state.txsOut.length > 0){
      const dateNow = new Date();
      return(
        this.state.txsOut.map(tx => {
          var txDate = parseInt((dateNow-tx.txTime)/1000);
          if(txDate > 3600){
            txDate = parseInt(txDate/3600)+"h";
          }else if(txDate > 60){
            txDate = parseInt(txDate/60)+"m";
          }else{
            txDate = txDate+"s"
          }
          var txAmount = 0;
          var txFee = 0;
          var txFeeDenom = "UMEE";
          var sender;
          var receiver;
          tx.txMessage.forEach(messages => {
            if(messages.type === "send to eth"){
              messages.message.forEach(amount => {
                if(amount.key === "amount"){
                  txAmount += parseInt(amount.value.amount)
                }
                if(amount.key === "bridge_fee"){
                  txFee += parseInt(amount.value.amount)
                  txFeeDenom = amount.value.denom
                  if(txFeeDenom.length > 5 && txFeeDenom.includes("base")){
                    txFeeDenom = txFeeDenom.split("base")[1].toUpperCase();
                  }else if(txFeeDenom[0] === "u" || txFeeDenom[0] === "a" || txFeeDenom[0] === "t"){
                    txFeeDenom = txFeeDenom.substring(1);
                  }
                }
                if(amount.key === "sender"){
                  sender = amount.value
                }
                if(amount.key === "eth_dest"){
                  receiver = amount.value
                }
              })
            }
          })
          return(
            <a href={`/tx/${tx.txHash}`}>
              <div>
                <div className="row">
                  <div className="col-md-3 col-8">
                    {
                      tx.status === true ?
                        <p className="text-hash capitalize-text"><span className="badge bg-success rounded-pill">{tx.txMessage[0].type}</span></p>
                      :
                      <p className="text-hash capitalize-text"><span className="badge bg-danger rounded-pill">{tx.txMessage[0].type}</span></p>
                    }
                  </div>
                  <div className="col-md-3 col-4">
                    <p><span className="badge bg-secondary"><span className="stopwatch">{txDate}</span><u style={{"display": "none"}} className="timestampSaver">{parseInt(tx.txTime)}</u> ago</span></p>
                  </div>
                  <div className="col-md-3 col-6">
                    <p className="normal-text"><b>{(parseInt(txAmount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} </b>
                    <span className="badge bg-primary">
                    {
                      tx.txDenom ?
                        ` ${tx.txDenom.toUpperCase()}`
                      : ` ${denomination}`
                    }
                    </span>
                    </p>
                  </div>
                  <div className="col-md-3 col-6">
                    <p className="normal-text">Fee <b>{(parseInt(txFee)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6})} </b><span className="badge bg-primary">{txFeeDenom.toUpperCase()}</span></p>
                  </div>
                  <div className="col-6">
                    <p className="text-hash content-table-title"><i className="bi bi-arrow-90deg-up text-danger"></i> {sender}</p>
                  </div>
                  <div className="col-6">
                    <p className="text-hash content-table-title"><i className="bi bi-arrow-return-right text-primary"></i> {receiver}</p>
                  </div>
                  <div className="col-12">
                    <p className="text-hash content-table-title">{tx.txHash}</p>
                  </div>
                </div>
              </div>
              <hr className="no-margin-hr" />
            </a>
          )
        })
      )
    }
  }
  render(){
    return(
      <>
      <ReactTooltip id="info-text" type="dark">
        Last 5,000 block
      </ReactTooltip>
      <div className="summary-div" data-theme={this.props.fullData.theme}>
        <div className="summary shadow-sm" data-theme="dark">
          <div className="row">
            <div className="col-md-6 col-6">
              <div className="title-div">
                <div className="title">
                  <p><i className="bi bi-coin"></i> Tokens</p>
                </div>
                <div className="title-value">
                  {this.state.tokens ?
                    <p>{parseInt(this.state.tokens.length).toLocaleString()}</p>
                  : <p>0</p>}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-6">
              <div className="title-div">
                <div className="title">
                  <p><i className="bi bi-chevron-bar-contract"></i> UMEE Bridged</p>
                </div>
                <div className="title-value">
                  {this.state.status ?
                    <p>{((parseInt(this.state.status.txsIn) + parseInt(this.state.status.txsOut))/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 0})}</p>
                  : <p>0</p>}
                </div>
              </div>
            </div>
            {/* <div className="col-md-3 col-6">
              <div className="title-div">
                <div className="title">
                  <p><i className="bi bi-coin"></i> Total Txs. In</p>
                </div>
                <div className="title-value">
                  {this.state.tokens ?
                    <p>{parseInt(this.state.status.amountIn).toLocaleString()}</p>
                  : <p>0</p>}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="title-div">
                <div className="title">
                  <p><i className="bi bi-coin"></i> Total Txs. Out</p>
                </div>
                <div className="title-value">
                  {this.state.tokens ?
                    <p>{parseInt(this.state.status.amountOut).toLocaleString()}</p>
                  : <p>0</p>}
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="summary shadow-sm" style={{borderRadius: "4px", paddingTop: "12px"}}>
              <strong className="title-text mx-6">Latest Bridge In <i className="bi bi-info" data-tip data-for="info-text"></i></strong>
              <hr/>
              {
                this.state.txsIn && this.state.tokens ?
                  <>
                  {this.renderIn()}
                  <div className="row">
                    <div className="col-5">
                      <button className="btn button-pagination-left" data-theme={this.props.fullData.theme} onClick={this.prevIn} disabled={this.state.pageIn === 0 ? true : false}><i className="bi bi-arrow-left"></i> Previous</button>
                    </div>
                    <div className="col-2">
                      <p className="normal-text mt-2 text-center">Page {this.state.pageIn+1}</p>
                    </div>
                    <div className="col-5">
                      <button className="btn button-pagination-right" data-theme={this.props.fullData.theme} onClick={this.nextIn}>Next <i className="bi bi-arrow-right"></i></button>
                    </div>
                  </div>
                  </>
                :
                <div className="summary shadow-sm" style={{"textAlign": "center", "height": "700px"}} data-theme={this.props.fullData.theme}>
                  <div style={{margin: "0 auto", marginTop: "350px"}}>
                    <div class="o1"></div>
                    <div class="o2"></div>
                    <div class="o3"></div>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="col-md-6">
            <div className="summary shadow-sm" style={{borderRadius: "4px", paddingTop: "12px"}}>
              <strong className="title-text mx-6">Latest Bridge Out <i className="bi bi-info" data-tip data-for="info-text"></i></strong>
              <hr/>
              {
                this.state.txsOut ?
                <>
                  {this.renderOut()}
                  <div className="row">
                    <div className="col-5">
                      <button className="btn button-pagination-left" data-theme={this.props.fullData.theme} onClick={this.prevOut} disabled={this.state.pageOut === 0 ? true : false}><i className="bi bi-arrow-left"></i> Previous</button>
                    </div>
                    <div className="col-2">
                      <p className="normal-text mt-2 text-center">Page {this.state.pageOut+1}</p>
                    </div>
                    <div className="col-5">
                      <button className="btn button-pagination-right" data-theme={this.props.fullData.theme} onClick={this.nextOut}>Next <i className="bi bi-arrow-right"></i></button>
                    </div>
                  </div>
                </>
                :
                <div className="summary shadow-sm" style={{"textAlign": "center", "height": "700px"}} data-theme={this.props.fullData.theme}>
                  <div style={{margin: "0 auto", marginTop: "350px"}}>
                    <div class="o1"></div>
                    <div class="o2"></div>
                    <div class="o3"></div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

export default connect(mapStateToProps)(Bridge);