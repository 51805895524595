import React from 'react';
import { projectName, apiURL, decimals } from '../data/API';
import Axios from 'axios';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";

class Stats extends React.Component {
  state={
    optionsIn: [],
    seriesIn: [],
    checkBridge: false
  }
  componentDidMount(){
    document.title = `Chain Statistics | ${projectName} Scan`;
    this.getStats()
  }
  getStats(){
    Axios.get(`${apiURL}/stats`)
    .then(res => {
      this.setState({
        stats: res.data.data
      })
    })
  }
  renderStats(){
    var dataAmountIn = [];
    var dataTxsIn = [];
    var dataAmountOut = [];
    var dataFeeOut = [];
    var dataTxsOut = [];
    var blocks = [];
    for(var block = this.state.stats.toBlock; block <= this.state.stats.fromBlock; block++) {
      blocks.push(block);
      const getIndexIn = this.state.stats.bridgeIn.findIndex(x => x.block === block);
      if(getIndexIn >= 0) {
        dataTxsIn.push(this.state.stats.bridgeIn[getIndexIn].txs);
        dataAmountIn.push((parseInt(this.state.stats.bridgeIn[getIndexIn].totalAmount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6}));
      }else{
        dataTxsIn.push(0);
        dataAmountIn.push(0);
      }
      const getIndexOut = this.state.stats.bridgeOut.findIndex(x => x.block === block);
      if(getIndexOut >= 0) {
        dataTxsOut.push(this.state.stats.bridgeOut[getIndexOut].txs);
        dataAmountOut.push((parseInt(this.state.stats.bridgeOut[getIndexOut].totalAmount)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6}));
        dataFeeOut.push((parseInt(this.state.stats.bridgeOut[getIndexOut].totalFee)/10**decimals).toLocaleString(undefined, {maximumFractionDigits: 6}));
      }else{
        dataTxsOut.push(0);
        dataAmountOut.push(0);
        dataFeeOut.push(0);
      }
    }
    
    const seriesIn = [
      {
        name: 'Total Amount',
        type: 'column',
        data: dataAmountIn
      },
      {
        name: 'Transaction',
        type: 'line',
        data: dataTxsIn
      }
    ]
    const optionsIn = {
      chart: {
        height: '100%',
        type: 'line',
      },
      stroke: {
        width: [0, 4]
      },
      labels: blocks,
      xaxis: {
        type: 'Block'
      },
      yaxis: [
        {
          title: {
            text: 'Total Amount',
          },
        },
        {
          opposite: true,
          labels: {
            formatter: (value) => { return value.toLocaleString() },
          },
          title: {
            text: 'Transaction'
          }
        }
      ]
    }
    const seriesOut = [
      {
        name: 'Total Amount',
        type: 'column',
        data: dataAmountOut
      },
      {
        name: 'Transaction',
        type: 'line',
        data: dataTxsOut
      },
      {
        name: 'Total Fee',
        type: 'column',
        data: dataFeeOut
      }
    ]
    const optionsOut = {
      chart: {
        height: '100%',
        type: 'line',
      },
      stroke: {
        width: [0, 4]
      },
      labels: blocks,
      xaxis: {
        type: 'Block'
      },
      yaxis: [
        {
          title: {
            text: 'Total Amount',
          },
        },
        {
          labels: {
            formatter: (value) => { return value.toLocaleString() },
          },
          opposite: true,
          title: {
            text: 'Transaction'
          }
        }
      ]
    }
    const series = [
      {
        name: 'Transaction',
        type: 'column',
        data: this.state.stats.txs
      },
      {
        name: 'Signed Validator',
        type: 'line',
        data: this.state.stats.validators
      }
    ]
    const options = {
      chart: {
        height: '100%',
        type: 'line',
      },
      stroke: {
        width: [0, 4]
      },
      labels: blocks,
      xaxis: {
        type: 'Block'
      },
      yaxis: [
        {
          title: {
            text: 'Transaction',
          },
        },
        {
          opposite: true,
          title: {
            text: 'Signed Validator'
          }
        }
      ]
    }
    return(
      <>
      <div className="col-md-12">
        <div className="summary shadow-sm">
          <strong className="title-text mx-6">Bridge In</strong>
          <hr/>
            <Chart options={optionsIn} series={seriesIn} type="line" height={350} />
        </div>
      </div>
      <div className="col-md-12">
        <div className="summary shadow-sm">
          <strong className="title-text mx-6">Bridge Out</strong>
          <hr/>
            <Chart options={optionsOut} series={seriesOut} type="line" height={350} />
        </div>
      </div>
      <div className="col-md-12">
        <div className="summary shadow-sm">
          <strong className="title-text mx-6">Validator & Transaction</strong>
          <hr/>
            <Chart options={options} series={series} height={350} />
        </div>
      </div>
    </>
    )
  }

  render() {
    return (
      <>
      <div className="summary-div">
        <div className="row">
          {
            this.state.stats ?
              this.renderStats()
            :
            <div className="summary shadow-sm" style={{"textAlign": "center", height: "622px"}} data-theme={this.props.fullData.theme}>
              <div style={{margin: "0 auto", marginTop: "275px"}}>
                <div class="o1"></div>
                <div class="o2"></div>
                <div class="o3"></div>
              </div>
            </div>
          }
        </div>
      </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fullData: state.user
  }
};

export default connect(mapStateToProps)(Stats);